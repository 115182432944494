<template>
  <div>
    <el-card class="mb20" shadow="hover">
      <el-row type="flex" :gutter="20" align="middle">
          <el-input
            :placeholder="$t('search')"
            clearable
            v-model="filter.search"
            @keyup.enter.native="get_list"
          ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" :gutter="20" align="middle">
          <span class="text">{{$t('notice.active')}}:</span>
          <el-radio-group size="medium" v-model="filter.active">
               <el-radio label>{{$t('all')}}</el-radio>
              <el-radio :label="0">{{active(0)}}</el-radio>
              <el-radio :label="1">{{active(1)}}</el-radio>
          </el-radio-group>
      </el-row>
      <el-row class="mt20" type="flex" :gutter="20" align="middle">
          <span class="text">{{$t('notice.is_home')}}:</span>
          <el-radio-group size="medium" v-model="filter.is_home">
               <el-radio label>{{$t('all')}}</el-radio>
              <el-radio :label="0">{{is_home(0)}}</el-radio>
              <el-radio :label="1">{{is_home(1)}}</el-radio>
          </el-radio-group>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
          <el-button @click="get_list" type="primary">{{$t('search_zh')}}</el-button>
          <el-button @click="reset" type="info">{{$t('btnTip.reset')}}</el-button>
        </el-row>
    </el-card>
    <el-card>
      <div class="flexBC mb20">
         <el-button
          type="primary"
          @click="handleClick('add')"
        >{{$t('btnTip').add}}</el-button>
         <el-button
          type="primary"
          @click="outExe"
          >{{$t('outExe')}}</el-button>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{padding: '6px 0'}"
        :headerCellStyle="{background:'rgba(51, 55, 68, 1)',color:'#fff'}"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
      <template v-slot:content="slotProps">
            <div class="mutiple" v-html="slotProps.callback.row.content"></div>
        </template>
        <template v-slot:active="slotProps">
          <el-tag :type="activeTag(slotProps.callback.row.active)">{{active(slotProps.callback.row.active)}}</el-tag>
        </template>
        <template v-slot:is_home="slotProps">
          <el-tag :type="is_homeTag(slotProps.callback.row.is_home)">{{is_home(slotProps.callback.row.is_home)}}</el-tag>
        </template>
        <template v-slot:operation="slotProps">
        <el-tooltip class="item" effect="dark" :content="slotProps.callback.row.active===0?$t('notice.active1'):$t('notice.active0')" placement="top">
            <el-button
              @click="handleClick('active',slotProps.callback.row)"
              :type="slotProps.callback.row.active===0?'success':'warning'"
              size="mini"
            >{{slotProps.callback.row.active===0?$t('notice.active1'):$t('notice.active0')}}</el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="$t('btnTip').edit" placement="top">
            <el-button
              @click="handleClick('edit',slotProps.callback.row)"
              icon="el-icon-edit-outline"
              type="primary"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="$t('btnTip').delete" placement="top">
            <el-button
              @click="remove(slotProps.callback.row.id)"
              icon="el-icon-delete"
              type="danger"
              size="mini"
            ></el-button>
          </el-tooltip>
        </template>
      </my-table>
      <page :total="list_data.total" :page_size.sync="pageSize" :page.sync="page" />
    </el-card>
  </div>
</template>

<script>
import {  mixin_list } from "@/mixins";
import myTable from '@/components/table'
import page from "@/components/page";
import {  noticeList, noticeDelete, noticeEdit } from '@/api/index';
export default {
  mixins: [ mixin_list(noticeList)],
  components: {
    page,
    myTable
  },
  data () {
    return {
      filter: {
        search: '',
        is_home:'',
        active:''
      },
      columns: [
        {
          label: 'ID',
          prop: 'id',
          width: 80,
          sortable:true
        }, 
        {
          label: this.$t('notice.create_time'),
          prop: 'create_time',
          sortable:true
        },
        {
          label: this.$t('notice.update_time'),
          prop: 'update_time',
          sortable:true
        },
        {
          label: this.$t('notice.title'),
          prop: 'title',
          sortable:true
        },
        {
          label: this.$t("notice.subtitle"),
          sortable: true,
          prop: "subtitle"
        },
        {
          label: this.$t('notice.content'),
          prop: 'content',
          slot:'content',
          sortable:true
        },
        {
          label: this.$t('notice.order'),
          prop: 'order',
          sortable:true
        },
        {
          label: this.$t('notice.active'),
          prop: 'active',
          slot:'active',
          sortable:true
        },
        {
          label: this.$t('notice.is_home'),
          prop: 'is_home',
          slot:'is_home',
          sortable:true
        },
        {
          label: this.$t('operation'),
          prop: '',
          width: 200,
          slot: 'operation'
        }]
    }
  },
  methods: {
    is_home(is_home){
          switch(is_home){
              case 0:
                  return this.$t('notice.is_home0')
                case 1:
                  return this.$t('notice.is_home1')
          }
      },
      is_homeTag(is_homeTag){
        switch(is_homeTag){
          case 0:
            return 'info'
          case 1:
            return 'success'
        }
      },
      active(active){
          switch(active){
              case 0:
                  return this.$t('notice.active0')
                case 1:
                  return this.$t('notice.active1')
          }
      },
      activeTag(activeTag){
        switch(activeTag){
          case 0:
            return 'info'
          case 1:
            return 'success'
        }
      },
    async handleClick (value, item) {
      switch (value) {
          case 'active':
              delete item.create_time
              delete item.update_time
            await noticeEdit({...item,active:item.active===0?1:0})
            this.get_list()
          break;
        case 'edit':
            this.$router.push(`/customerCenter/notice/edit?id=${item.id}`)
          break;
        case 'add':
            this.$router.push(`/customerCenter/notice/edit`)
          break;
      }
    },
    remove (id) {
      this.$confirm(this.$t('sureDelete'), this.$t('tips'), {
        confirmButtonText: this.$t('btnTip.submit'),
        cancelButtonText: this.$t('btnTip.cancel'),
        type: 'warning'
      }).then(async () => {
        await noticeDelete(id)
        this.get_list()
      }).catch(() => {
        
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.text{
  width:100px;
  font-size: 16px;
}
/deep/ label{
  margin-bottom:0!important
}
</style>